<script>
import OrgItems from '@/views/processManagement/component/config/OrgItems.vue'
import OrgPicker from '@/components/OrgPicker.vue'
import { eventBus } from '@/event/eventBus'

export default {
  name: 'ApprovalNodeConfig',
  components: { OrgPicker, OrgItems },
  props: {
    config: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      newThing: '',
      sort: 'order',
      newSelect: [],
      option: '',
    }
  },
  computed: {
    select() {
      return this.config.assignedUser
    },
    handleOrder() {
      return this.config.handleOrder.option
    },
  },
  mounted() {
    eventBus.$on('DataCheck', this.validateProcess)
    this.newSelect = [...this.config.assignedUser]
    this.option = this.config.handleOrder.option
  },
  beforeDestroy() {
    eventBus.$off('DataCheck', this.validateProcess)
  },

  methods: {
    selectOrg() {
      this.$refs.orgPicker.show()
    },
    selected(select) {
      this.newSelect.length = 0
      this.newSelect = select
      this.$forceUpdate()
    },
    removeOrgItem(index) {
      this.newSelect.splice(index, 1)
    },
    validateProcess(done) {
      if (this.newSelect.length === 0) return this.$message.warning('请选择审批人')

      this.select.length = 0
      this.newSelect.forEach(val => this.select.push(val))
      // eslint-disable-next-line vue/no-mutating-props
      this.config.handleOrder.option = this.option
      done()
    },
  },
}
</script>

<template>
  <div class="px15">
    <div class="desc mb12">选择审批人</div>
    <v-btn color="primary" small @click="selectOrg">
      <v-icon left>mdi-plus</v-icon>
      请选择
    </v-btn>
    <org-items v-model="newSelect" />
    <p class="desc mb12  mt30">办理顺序</p>
    <el-radio-group v-model="option">
      <el-radio label="order">依次办理（按顺序依次办理）</el-radio>
      <el-radio label="or">或办（一名成员办理即可）</el-radio>
    </el-radio-group>
    <org-picker ref="orgPicker" :selected="newSelect" multiple title="请选择审批人" @ok="selected" />
  </div>
</template>

<style lang="scss" scoped>
.desc{
  height: 20px;
  font-size: 14px;
  font-family: PingFang-SC, PingFang-SC;
  font-weight: 500;
  color: rgba(0,0,0,0.85);
}
.org-item{
  margin: 0 5px 5px;
}
</style>
