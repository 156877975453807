<script>
import ProcessTree from '@/views/processManagement/component/ProcessTree.vue'
import NodeConig from '@/views/processManagement/component/config/NodeConig.vue'
import { eventBus } from '@/event/eventBus'

export default {
  name: 'ProcessView',
  components: { NodeConig, ProcessTree },
  data() {
    return {
      scale: 100,
      showConfig: false,
      showInput: false,
    }
  },
  computed: {
    selectedNode() {
      return this.$store.state.selectedNode
    },
  },
  methods: {
    nodeSelected(node) {
      this.showConfig = true
    },
    handleClose(done) {
      eventBus.$emit('DataCheck', done)
    },
    closeDown() {
      this.$refs.configRef.closeDrawer()
    },
  },
}
</script>

<template>
  <el-main>
    <div class="scale">
      <el-button :disabled="scale >= 150" circle icon="el-icon-plus" size="small" @click="scale += 10"></el-button>
      <span>{{ scale }}%</span>
      <el-button :disabled="scale <= 40" circle icon="el-icon-minus" size="small" @click="scale -= 10"></el-button>
    </div>

    <div :style="'transform: scale('+ scale / 100 +');'" class="design">
      <process-tree ref="process-tree" @selectedNode="nodeSelected" />
    </div>

    <el-drawer
      ref="configRef"
      :before-close="handleClose"
      :close-on-press-escape="false"
      :title="selectedNode.name"
      :visible.sync="showConfig"
      destroy-on-close
      :modal-append-to-body="false"
      :wrapper-closable="false"
      direction="rtl"
      size="400px"
    >
      <div slot="title">
        <el-input
          v-show="showInput" v-model="selectedNode.name" size="medium"
          style="width: 300px" @blur="showInput = false"
        />
        <el-link v-show="!showInput" style="font-size: medium" @click="showInput = true">
          <span
            style="font-size: 20px; font-weight: 600; color: #000000;"
          >
            {{ selectedNode.name }}
          </span>
        </el-link>
      </div>
      <div class="node-config-content px5 mt24">
        <node-conig style="flex: auto" />

        <div class="footer px15">
          <v-btn class="mr8" color="primary" outlined small @click="showConfig = false">取消</v-btn>
          <v-btn color="primary" small @click="closeDown">确认</v-btn>
        </div>
      </div>
    </el-drawer>
  </el-main>
</template>

<style lang='scss' scoped>
.design {
  margin-top: 100px;
  display: flex;
  transform-origin: 50% 0 0;
}
.scale {
  z-index: 999;
  position: fixed;
  top: 25px;
  right: 40px;

  span {
    margin: 0 10px;
    font-size: 15px;
    color: #7a7a7a;
    width: 50px;
  }
}

::v-deep .el-drawer__header {
  margin-bottom: 0;
  padding: 0 20px;
  height: 60px;
  border-bottom: 1px solid #e8e8e8;
}

.node-config-content {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 120px);

  .footer {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
