<template>
  <div class="px15">
    <p class="desc mb12">选择能发起该审批的人员/部门，不选则默认开放给所有人</p>
    <v-btn color="primary" small @click="selectOrg">
      <v-icon left>mdi-plus</v-icon>
      请选择
    </v-btn>
    <org-items v-model="select" />
    <org-picker ref="orgPicker" :selected="select" multiple title="请选择可发起本审批的人员/部门" @ok="selected" />
  </div>
</template>

<script>
import OrgPicker from '@/components/OrgPicker.vue'
import { eventBus } from '@/event/eventBus'
import OrgItems from './OrgItems.vue'

export default {
  name: 'RootConfig',
  components: { OrgPicker, OrgItems },
  props: {
    config: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      showOrgSelect: false,
    }
  },
  computed: {
    select() {
      return this.config.assignedUser
    },
  },
  mounted() {
    eventBus.$on('DataCheck', this.validateProcess)
  },
  beforeDestroy() {
    eventBus.$off('DataCheck', this.validateProcess)
  },
  methods: {
    selectOrg() {
      this.$refs.orgPicker.show()
    },
    selected(select) {
      this.select.length = 0
      select.forEach(val => this.select.push(val))
      this.$forceUpdate()
    },
    removeOrgItem(index) {
      this.select.splice(index, 1)
    },
    validateProcess(done) {
      done()
    },
  },
}
</script>

<style lang="scss" scoped>
.desc{
  font-size: small;
  color: #8c8c8c;
}
.org-item{
  margin: 5px;
}
</style>
