import { render, staticRenderFns } from "./InsertButton.vue?vue&type=template&id=7b7bb509&scoped=true"
import script from "./InsertButton.vue?vue&type=script&lang=js"
export * from "./InsertButton.vue?vue&type=script&lang=js"
import style0 from "./InsertButton.vue?vue&type=style&index=0&id=7b7bb509&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b7bb509",
  null
  
)

export default component.exports