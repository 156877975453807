<script>
import ProcessView from '@/views/processManagement/component/ProcessView.vue'
import FormItemPlus from '@/components/FormItemPlus.vue'
import { getAppList, getDetail, saveApp } from '@/views/processManagement/service'
import { required } from '@core/utils/validation'
import { toastFail, toastInfo } from '@core/utils/prompt'
import { isEmpty } from '@/views/workFlow/utils'

export default {
  name: 'Add',
  components: { FormItemPlus, ProcessView },
  data() {
    return {
      required,
      stepBar: [
        { value: 1, label: '基础配置' },
        { value: 2, label: '流程设计' },
      ],
      activeStep: 1,
      formList: {},
      appList: [],
      formData: {
        remark: '', // 备注
        formName: '', // 表单名
        formId: '', // 表单ID
        templateId: '', // 流程类型
      },
    }
  },
  created() {
    this.init()
  },
  mounted() {
    this.getListApp()
  },
  methods: {
    init() {
      const { id, type } = this.$route.query

      if (id) {
        getDetail(id).then(({ data, status }) => {
          if (status === 200) {
            // 编辑
            if (type === 'edit') {
              this.formData.remark = data.remark
              this.formData.formName = data.formName
              this.formData.formId = data.groupId
              this.formData.templateId = data.templateId
              this.$store.commit('loadForm', {
                formId: data.templateId,
                formName: data.formName,
                groupId: data.groupId,
                formItems: [],
                process: data.process ? JSON.parse(data.process) : {
                  id: 'root',
                  parentId: null,
                  type: 'ROOT',
                  name: '发起人',
                  desc: '任何人',
                  props: {
                    assignedUser: [],
                    formPerms: [],
                    handleOrder: {
                      option: 'order', // order依次  or或签
                    },
                  },
                  children: {},
                },
                remark: data.remark,
              })
            }

            // 复制
            if (type === 'copy') {
              this.$store.commit('loadForm', {
                formId: data.templateId,
                formName: data.formName,
                groupId: data.groupId,
                formItems: [],
                process: data.process ? JSON.parse(data.process) : {
                  id: 'root',
                  parentId: null,
                  type: 'ROOT',
                  name: '发起人',
                  desc: '任何人',
                  props: {
                    assignedUser: [],
                    formPerms: [],
                    handleOrder: {
                      option: 'order', // order依次  or或签
                    },
                  },
                  children: {},
                },
                remark: data.remark,
              })
            }
          }
        })
      } else {
        this.loadInitFrom()
      }
    },
    loadInitFrom(formName = '未命名表单') {
      this.$store.commit('loadForm', {
        formId: null,
        formName,
        groupId: undefined,
        formItems: [],
        process: {
          id: 'root',
          parentId: null,
          type: 'ROOT',
          name: '发起人',
          desc: '任何人',
          props: {
            assignedUser: [],
            formPerms: [],
            handleOrder: {
              option: 'order', // order依次  or或签
            },
          },
          children: {},
        },
        remark: '备注说明',
      })
    },

    // 获取引用列表
    getListApp() {
      getAppList().then(res => {
        if (res.status === 200) {
          this.appList = (res.data || []).map(item => {
            this.formList[item.id] = (item.items || []).map(value => ({ ...value, text: value.formName, value: value.formId }))

            return {
              ...item,
              text: item.name,
              value: item.id,
            }
          })
        }
      })
    },

    // 类型修改
    typeChange(val) {
      if (val !== this.formData.formId) {
        this.formData.formId = val

        // 切换类型清空流程名称
        this.formData.templateId = ''
        this.formData.formName = ''
      }
    },

    // 下一步
    jump() {
      // const blo = this.$refs.formRef.validate()
      // if (!blo) return toastInfo('请填写完整信息')
      this.activeStep = 2
    },

    publish() {
      let currentNode = this.$store.state.design.process
      let index = 0
      const data = {
        templateId: this.formData.templateId,
        remark: this.formData.remark,
        process: JSON.stringify(this.$store.state.design.process),
      }

      while (!isEmpty(currentNode.children)) {
        // eslint-disable-next-line no-unused-vars
        index += 1
        if (currentNode.type !== 'ROOT' && currentNode.props.assignedUser.length === 0) {
          toastFail(`第${index}节点参数未配置正确`)
          break
        }
        currentNode = currentNode.children
      }

      if (index === 1) return toastFail('未配置流程节点')

      saveApp(data).then(() => {
        this.$router.push({ path: '/processManagement/List' })
      }).catch(error => {
        toastFail(error.response.data.message)
      })
    },
  },
}
</script>

<template>
  <div class="app-view">
    <div class="app-view-header">
      <v-spacer />
      <div style="width: 500px">
        <v-stepper
          v-model="activeStep"
          non-linear
          style="width: 100%; box-shadow: none"
        >
          <v-stepper-header style="height: 100%; box-shadow: none">
            <template v-for="(item, index) in stepBar">
              <v-stepper-step
                :key="item.value + item.label"
                :complete="activeStep > item.value"
                :step="item.value"
                style="padding: 0 20px"
              >
                <span class="fs22">{{ item.label }}</span>
              </v-stepper-step>

              <v-divider
                v-if="index + 1 < stepBar.length"
                :key="index"
              />
            </template>
          </v-stepper-header>
        </v-stepper>
      </div>
      <v-spacer />
    </div>
    <div class="app-view-main">
      <div v-if="activeStep === 1" class="form-main">
        <v-form ref="formRef">
          <form-item-plus label="流程类型">
            <v-select
              v-model="formData.formId"
              :items="appList"
              :rules="[required]"
              dense
              hide-details
              outlined
              placeholder="请选择"
              @change="typeChange"
            />
          </form-item-plus>
          <form-item-plus label="流程名称">
            <v-select
              v-model="formData.templateId"
              :items="formList[formData.formId] || []"
              :rules="[required]"
              dense
              hide-details
              outlined
              placeholder="请选择"
            />
          </form-item-plus>
          <form-item-plus label="流程说明">
            <v-textarea
              v-model="formData.remark"
              dense
              hide-details
              outlined
              placeholder="请输入"
            />
          </form-item-plus>
          <form-item-plus label="流程管理员">
            <div class="fs16" style="height: 38px; line-height: 38px">超级管理员</div>
          </form-item-plus>
        </v-form>
      </div>
      <process-view v-if="activeStep === 2" />
    </div>
    <div class="app-view-footer">
      <v-spacer />
      <v-btn color="primary" outlined @click="$router.back()">返回</v-btn>
      <v-btn :disabled="activeStep === 1" :outlined="activeStep === 2" color="primary" @click="activeStep = 1">上一步</v-btn>
      <v-btn v-if="activeStep === 1" color="primary" @click="jump">下一步</v-btn>
      <v-btn v-if="activeStep === 2" :disabled="activeStep !== 2" color="primary" @click="publish">发布</v-btn>
    </div>
  </div>
</template>

<style lang='scss' scoped>
.app-view {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100vh;

  &-header {
    height: 64px;
    background-color: #fff;
    margin: 10px 20px;
    display: flex;
    align-items: center;
  }

  &-main {
    flex: auto;
    padding: 0 20px;
    height: calc(100% - 216px);
    overflow: hidden;
    overflow-y: auto;
  }

  &-footer {
    padding: 0 20px;
    display: flex;
    align-items: center;
    height: 64px;
    background-color: #fff;
    margin: 10px 20px;
    gap: 8px;
  }
}

::v-deep .v-stepper__step__step {
  width: 32px;
  height: 32px;
  font-size: 14px;
}

.form-main {
  display: flex;
  justify-content: center;
  background-color: #fff;
  height: 100%;
  padding-top: 30px;
}
</style>
