import http from '@flowHttp'

// 获取组织架构树
export function getOrgTree(params) {
  return http.get('/oa/org/tree', params)
}

// 搜索用户
export function getUserByName(params) {
  return http.get('/oa/org/tree/user/search', params)
}

// 查询流程列表
export function getFlowList(params) {
  return http.post('/admin/form/processList', params)
}

// 获取app应用数据
export function getAppList() {
  return http.get('/admin/form/group')
}

// 新增数据
export function saveApp(params) {
  return http.post('/admin/process/design', params)
}

// 获取详情
export function getDetail(formId) {
  return http.get(`/admin/form/detail/${formId}`)
}
