// 审批节点默认属性
export const APPROVAL_PROPS = {
  assignedType: 'ASSIGN_USER',
  mode: 'AND',
  sign: false,
  nobody: {
    handler: 'TO_PASS',
    assignedUser: [],
  },
  assignedUser: [],
  formPerms: [],
  selfSelect: {
    multiple: false,
  },
  handleOrder: {
    option: 'order', // order依次  or或签
  },
  formUser: '',
}

// 根节点默认属性
export const ROOT_PROPS = {
  assignedUser: [],
  formPerms: [],
}

// 抄送节点默认属性
export const CC_PROPS = {
  assignedType: 'ASSIGN_USER',
  nobody: {
    handler: 'TO_PASS',
    assignedUser: [],
  },
  shouldAdd: false,
  assignedUser: [],
  formPerms: [],
}

// 办理节点默认
export const TRIGGER_PROPS = {
  assignedType: 'ASSIGN_USER',
  assignedUser: [],
  formPerms: [],
  sort: 1,
  handleOrder: {
    option: 'order', // order依次  or或签
  },
  handleAffairs: {
    content: '',
  },
  thing: '',
}

export default {
  APPROVAL_PROPS, CC_PROPS, ROOT_PROPS, TRIGGER_PROPS,
}
