import { modalFail } from '@core/utils/prompt'

export const debounce = (fn, interval) => {
  let timeout = null // 创建一个标记用于表示是否存在定时器（有定时器表示在防抖中，通过闭包保存该标记）

  return (...args) => {
    clearTimeout(timeout) // 防抖函数触发时先清除之前的定时器
    timeout = setTimeout(() => { // 清除之前的定时器后重新创建定时器定时，只有在interval时间内不触发防抖函数才会执行该防抖函数
      fn.apply(this, ...args) // 通过apply传入参数
    }, interval)
  }
}

export const throttle = (fn, interval) => {
  let canRun = true // 创建一个标记用于表示是否可以运行（不能运行表示在节流中，通过闭包保存该标记）

  return (...args) => {
    if (!canRun) return // 在函数开始就判断是否能运行，不能就直接return（还在节流中）
    canRun = false // 若已经超过节流interval则可运行，先设置标记为不能运行
    setTimeout(() => { // 设置定时器并传入interval，即在interval时间内为节流时间
      fn.apply(this, ...args) // 通过apply传入参数
      canRun = true // 函数执行后则重新设置标记为可运行
    }, interval)
  }
}
