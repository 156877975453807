<script>
export default {
  name: 'FormItemPlus',
  props: {
    label: {
      type: String,
      default: '',
    },
    labelWidth: {
      type: String,
      default: '80px',
    },
    top: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
  },
}
</script>

<template>
  <div :class="{ 'form-item--top': top }" class="form-item">
    <div
      :class="{ 'form-item-label-dark' :$vuetify.theme.dark}"
      :style="{ width: labelWidth }"
      class="form-item-label"
    >
      <slot name="label">
        {{ label }}
      </slot>
    </div>
    <div :class="{ wit: true ,to: type === 'T'}" style="width: 425px">
      <slot />
    </div>
    <div v-if="$slots.append">
      <slot name="append" />
    </div>
  </div>
</template>

<style lang='scss' scoped>
.form-item {
  display: flex;
  align-items: stretch;
  gap: 8px;
  justify-content: flex-start;
  flex-wrap: nowrap;

  &-label {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    font-size: 16px;
    line-height: 38px;
    font-weight: 500;
    flex-shrink: 0;
    margin-right: 15px;
    color: rgba(0,0,0,0.85);
  }

  &-label-dark {
    color: #fff;
  }

  &--top {
    flex-direction: column;
    gap: 0;

    .form-item-label {
      justify-content: flex-start;
      font-size: 14px;
      padding-bottom: 6px;
      padding-left: 30px;
    }
  }

  .wit {
    width: 425px;
  }

  .to {
    width: 100% !important;
  }
}

.form-item + .form-item {
  margin-top: 24px;
}
</style>
