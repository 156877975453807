<template>
  <el-popover placement="bottom-start" title="添加流程节点" trigger="click">
    <div class="node-select">
      <div class="button-item" @click="addApprovalNode">
        <i class="el-icon-s-check fs24" style="color: #9155FD;" />
        <span class="fs12">审批人</span>
      </div>
      <div class="button-item" @click="addTriggerNode">
        <i class="el-icon-s-order" style="color: #1677FF;"></i>
        <span class="fs12">办理人</span>
      </div>
      <div class="button-item" @click="addCcNode">
        <i class="el-icon-s-promotion fs24" style="color: #0FC87F;" />
        <span class="fs12">抄送人</span>
      </div>
    </div>
    <el-button slot="reference" circle class="button-refer" icon="el-icon-plus" size="small" type="primary" />
  </el-popover>
</template>

<script>
export default {
  name: 'InsertButton',
  components: {},
  data() {
    return {}
  },
  computed: {
    selectedNode() {
      return this.$store.state.selectedNode
    },
  },
  methods: {
    addApprovalNode() {
      this.$emit('insertNode', 'APPROVAL')
    },
    addCcNode() {
      this.$emit('insertNode', 'CC')
    },
    addTriggerNode() {
      this.$emit('insertNode', 'HANDLER')
    },
  },
}
</script>

<style lang="scss" scoped>
.node-select{
  display: flex;

  div{
    display: inline-block;
    margin: 5px 5px;
    cursor: pointer;
    border: 1px solid #F8F9F9;
    background-color: #F8F9F9;
    border-radius: 10px;
    width: 52px;
    position: relative;

    &:hover{
      background-color: #fff;
      box-shadow: 0 0 8px 2px #d6d6d6;
    }
    i{
      font-size: 25px;
      padding: 5px;
      border-radius: 14px;
    }
  }
}
.button-item {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.button-refer {
  background-color: #7A8CA7;
  border-color: #7A8CA7;
  width: 30px;
  height: 30px;
  padding: 0;

  ::v-deep .el-icon-plus {
    font-size: 17px;
  }
}
</style>
