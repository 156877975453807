<template>
  <node
    :content="content" :error-info="errorInfo" :show-error="showError" :title="titleName"
    header-bgc="#1677FF" header-icon="el-icon-s-order" placeholder="请设置办理人"
    @delNode="$emit('delNode')" @insertNode="type => $emit('insertNode', type)" @selected="$emit('selected')"
  />
</template>

<script>
import Node from './Node.vue'

export default {
  name: 'Handler',
  components: { Node },
  props: {
    config: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      showError: false,
      errorInfo: '',
    }
  },
  computed: {
    content() {
      if (this.config.props.assignedUser.length > 0) {
        const texts = []
        this.config.props.assignedUser.forEach(org => texts.push(org.name))

        return String(texts).replaceAll(',', '、')
      }

      return '请设置办理人'
    },
    titleName() {
      if (this.config.props.handleAffairs.content) {
        return `${this.config.name}（${this.config.props.handleAffairs.content}）`
      }

      return this.config.name
    },
  },
}
</script>

<style scoped>

</style>
