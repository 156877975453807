<template>
  <node
    :content="content" :header-bgc="headerBgc" :is-root="true"
    header-icon="el-icon-user-solid" placeholder="新大陆下所有员工"
    title="发起人" @insertNode="type => $emit('insertNode', type)" @selected="$emit('selected')"
  />
</template>

<script>
import Node from './Node.vue'

export default {
  name: 'RootNode',
  components: { Node },
  props: {
    config: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  computed: {
    content() {
      if (this.config.props.assignedUser.length > 0) {
        const texts = []
        this.config.props.assignedUser.forEach(org => texts.push(org.name))

        return String(texts).replaceAll(',', '、')
      }

      return '新大陆下所有员工'
    },
    headerBgc() {
      if (this.$store.state.diagramMode === 'viewer') {
        return this.config.props.headerBgc
      }

      return '#9155FD'
    },
  },
  methods: {},
}
</script>

<style scoped>

</style>
