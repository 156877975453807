// 判断是否为空

export const toString = v => Object.prototype.toString.call(v)
export const isRefExp = val => toString(val) === '[object Regexp]'
const isObject = val => toString(val) === '[object Object]'
export function isEmpty(value) {
  if (value === null || value === undefined) return true
  if (typeof value === 'string' && value.trim().length === 0) return true
  if (Array.isArray(value) && value.length === 0) return true

  return isObject(value) && Reflect.ownKeys(value).length === 0
}
