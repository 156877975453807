<template>
  <node
    :content="content" :error-info="errorInfo" :show-error="showError" :title="config.name"
    header-bgc="#0FC87F" header-icon="el-icon-s-promotion" placeholder="请设置抄送人"
    @delNode="$emit('delNode')" @insertNode="type => $emit('insertNode', type)" @selected="$emit('selected')"
  />
</template>

<script>
import Node from './Node.vue'

export default {
  name: 'CcNode',
  components: { Node },
  props: {
    config: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      showError: false,
      errorInfo: '',
    }
  },
  computed: {
    content() {
      if (this.config.props.shouldAdd) {
        return '由发起人指定'
      } if (this.config.props.assignedUser.length > 0) {
        const texts = []
        this.config.props.assignedUser.forEach(org => texts.push(org.name))

        return String(texts).replaceAll(',', '、')
      }

      return null
    },
  },
  methods: {
    // 校验数据配置的合法性
    validate(err) {
      this.showError = false
      if (this.config.props.shouldAdd) {
        this.showError = false
      } else if (this.config.props.assignedUser.length === 0) {
        this.showError = true
        this.errorInfo = '请选择需要抄送的人员'
      }
      if (this.showError) {
        err.push(`抄送节点 ${this.config.name} 未设置抄送人`)
      }

      return !this.showError
    },
  },
}
</script>

<style scoped>

</style>
