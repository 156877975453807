<script>
import Cc from './CcNodeConfig.vue'
import Root from './RootNodeConfig.vue'
import Handler from './HandlerNodeConfig.vue'
import Approval from './ApprovalNodeConfig.vue'

export default {
  name: 'NodeConig',
  components: { Root, Handler, Cc, Approval },
  computed: {
    selectNode() {
      return this.$store.state.selectedNode
    },
  },
}
</script>

<template>
  <div>
    <component :is="(selectNode.type||'').toLowerCase()" :config="selectNode.props" />
  </div>
</template>

<style lang='scss' scoped>

</style>
